<template>
 <div>
  <Menu />
   <div class="cotinerdobv">
   </div>
   
    <div class="cotiner_status_del_image" align="center">
        <br/><br/><br/>
        <span>
             <h3> {{i}} / {{count_image}} </h3>
        </span>
    </div>

   <modalupload v-if="startupload" >
   <div slot="body">
     <table><tr><td><Spinner /></td><td ><span class="font_22">Идёт загрузка...</span></td></tr></table>
      
   </div>
   </modalupload>
  </div> 
</template>

<script>

import Spinner from '../blocks/spinner';
import Menu from '../blocks/menu';
import modalupload from '../blocks/modalupload';
import axios from "axios";
export default {
 
  components:{
    Spinner, modalupload, Menu  
  },
  data() {
     return {
     startupload:false,
     imgs:[],
     i:0,
     count_image:0,
     };
  },
  methods: {
  getdelimg(){
  if(localStorage.getItem("tokenadmin")){
   this.startupload=true;
      try{
         axios({
           method: 'post',
            url: 'https://avtobaza.tj/api/getdelimg',
            data: {
            tokenadmin:localStorage.getItem("tokenadmin")
           }
          })
        .then(res=>{
            if(res.data.status){
              this.startupload=false;
              if(res.data.imgs!="no"){
                 this.imgs=res.data.imgs;
                 this.count_image=this.imgs.length;
                 this.delimg(); 
              }
              else{
                alert("Нет картинка для удаления");
              }
            }
            else{
             this.startupload=false;
             alert("Произошла ошибка попробуйте снова");
            }    
        })    
     } 
     catch (e){
         alert ("Error");
     }
  }
  },
  delimg(){
    if(this.imgs[this.i].image){
      try{
         axios({
           method: 'post',
            url: 'https://imgagros.ru/api/delimg',
            data: {
            image_del:this.imgs[this.i].image,
            pass:localStorage.getItem("tokenadmin")
           }
          })
        .then(res=>{
           
          this.delimageinbaza();      
                
        })    
     } 
     catch (e){
         alert ("Error");
     }
    }else  this.delimageinbaza(); 
    
  },

  delimageinbaza(){
    try{
          axios({
          method: 'post',
              url: 'https://avtobaza.tj/api/delimgtable',
              data: {
              id:this.imgs[this.i].id,
              tokenadmin:localStorage.getItem("tokenadmin")
          }
          })
          .then(res=>{
              if(res.data.status){ 
                this.i++;
                if(this.i<this.count_image){
                  this.delimg(); 
                }
                else this.$router.go();;
              }
              else{
              
              alert("Произошла ошибка попробуйте снова");
              }    
          })    
      } 
      catch (e){
          alert ("Error");
      }
}

  },
  mounted(){
   this.getdelimg();

  },
  created(){
   if(!localStorage.getItem("tokenadmin")){
       this.$router.push({name:"Home"});
    }     
},
};
</script>

<style  scoped>
.img{
   width: 150px;
   height: 143px;
   background-color: #f5f5f5; 
}
.img img {
  width:auto;
  height: 100%;
}
</style>
