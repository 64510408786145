export default{
    actions: {
      
    },
    mutations: {
    addgorod(state, newpost){
        state.post.gorod=newpost;
    },
    addgorod_text(state, newpost){
        state.post.gorod_text=newpost;
    },
    addkateg(state, newpost){
        state.post.kateg=newpost;
    },
    addkateg_text(state, newpost){
        state.post.kateg_text=newpost;
    },
    addpodkateg(state, newpost){
        state.post.podkateg=newpost;
    },
    addpodkateg_text(state, newpost){
        state.post.podkateg_text=newpost;
    },
    addmarka(state, newpost){
        state.post.marka=newpost;
    },
    addmarka_text(state, newpost){
        state.post.marka_text=newpost;
    },
    addmodel(state, newpost){
        state.post.model=newpost;
    },
    addmodel_text(state, newpost){
        state.post.model_text=newpost;
    },
    addgod(state, newpost){
        state.post.god=newpost;
    },
    addkuzov(state, newpost){
        state.post.kuzov=newpost;
    },
    addkuzov_text(state, newpost){
        state.post.kuzov_text=newpost;
    },
    addcolor(state, newpost){
        state.post.color=newpost;
    },
    addcolor_text(state, newpost){
        state.post.color_text=newpost;
    },
    addtopliva(state, newpost){
        state.post.topliva=newpost;
    },
    addtopliva_text(state, newpost){
        state.post.topliva_text=newpost;
    },
    addkarobka(state, newpost){
        state.post.karobka=newpost;
    },
    addkarobka_text(state, newpost){
        state.post.karobka_text=newpost;
    },
    addprivod(state, newpost){
        state.post.privod=newpost;
    },
    addprivod_text(state, newpost){
        state.post.privod_text=newpost;
    },
    addtip(state, newpost){
        state.post.tip=newpost;
    },
    addtip_text(state, newpost){
        state.post.tip_text=newpost;
    },
    addkompleckt(state, newpost){
        state.post.kompleckt=newpost;
    },
    addkompleckt_text(state, newpost){
        state.post.kompleckt_text=newpost;
    },
    addobdvigatel(state, newpost){
        state.post.obdvigatel=newpost;
    },
    addsena(state, newpost){
        state.post.sena=newpost;
    },
    addname(state, newpost){
        state.post.name=newpost;
    },
    addbody(state, newpost){
        state.post.body=newpost;
    },
    addprobeg(state, newpost){
        state.post.probeg=newpost;
    },
    addtorg(state, newpost){
        state.post.torg=newpost;
    },
    addtitle(state, newpost){
        state.post.title=newpost;
    },
    addopsiya(state, newpost){
        state.post.opsiya=newpost;
    },
    addtipshina(state, newpost){
        state.post.tipshina=newpost;
    },
    addprofil(state, newpost){
        state.post.profil=newpost;
    },
    adddiametr(state, newpost){
        state.post.diametr=newpost;
    },
    addshirina(state, newpost){
        state.post.shirina=newpost;
    },
    addtipdiski(state, newpost){
        state.post.tipdiski=newpost;
    },
    addpcd(state, newpost){
        state.post.pcd=newpost;
    },
    addsostoyanie(state, newpost){
        state.post.sostoyanie=newpost;
    },
    addimg_p(state, newpost){
        state.post.img_p=newpost;
    },
    addchat(state, newpost){
        state.post.chat=newpost;
    },
    addid(state, newpost){
        state.post.id=newpost;
    },
    addpost(state, newpost){
        state.post=newpost;
    },
    addcar_bazar(state, newpost){
        state.post.car_bazar=newpost;
    },
    addgreat_price(state, newpost){
        state.post.great_price=newpost;
    },
    addpostAvtor(state, newpost){
        state.postAvtor=newpost;
    },
    addimages(state, newpost){
            state.images=newpost;
    },
   },
    state: {
        post:[],
        postAvtor:[],
        images:[],
        opsiya:[],
        
    },
    getters:{
        getpost(state){
            return state.post
        },
        getpostAvtor(state){
            return state.postAvtor
        },
        getopsiya(state){
            return state.opsiya
        },
        getimages(state){
           return state.images
        },
    }
}