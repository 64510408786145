<template>
 <div>
   <div >
     <Menu />  
   </div>
  <div class="content_moderator">
  <div>
    <br/>
    <h3>ID: {{post.id}}  DATE:  {{post.dt}} VIPTOP:  {{post.top}}</h3></div>

   <div>
     <div class="div_label">Категория</div>
      <router-link :to="{name:'kateg'}" >
       <div  class="div_border">{{post.kateg_text}}</div>   
      </router-link>  
   </div>


   <div>
     <div class="div_label">Подкатегория</div>
      <router-link :to="{name:'podkateg', params:{id:post.kateg}}" >
       <div  class="div_border">{{post.podkateg_text}}</div>   
      </router-link>  
   </div>


   <div>
     <div class="div_label">Город или регион</div>
      <router-link :to="{name:'gorod'}" >
       <div  class="div_border">{{post.gorod_text}}</div>   
      </router-link>  
    </div>

   <div>
     <div class="div_label">Заголовок</div>
     <input type="text" class="inputt" v-model.trim="title" @keyup="f_keyup('title')" placeholder="">
  </div> 
  
   <div v-if = "post.podkateg">
     <div class="div_label">Марка</div>
      <router-link :to="{name:'marka', params:{id:post.podkateg}}" >
       <div  class="div_border">{{post.marka_text}}</div>   
      </router-link>  
   </div>
   <div v-else>
     <div class="div_label">Марка</div>
      <router-link :to="{name:'marka', params:{id:post.kateg}}" >
       <div  class="div_border">{{post.marka_text}}</div>   
      </router-link>  
   </div>

   <div>
     <div class="div_label">Модел</div>
      <router-link :to="{name:'model', params:{id:post.marka}}" >
       <div  class="div_border">{{post.model_text}}</div>   
      </router-link>  
   </div>


   <div>
     <div class="div_label">Тип</div>
     <div  v-if="post.kateg=='moto'">
       <router-link :to="{name:'tip', params:{id:post.kateg}}" >
       <div  class="div_border">{{post.tip_text}}</div>   
      </router-link>  
     </div>
     <div v-else>
       <router-link :to="{name:'tip', params:{id:post.podkateg}}">
       <div  class="div_border">{{post.tip_text}}</div>   
      </router-link>
     </div>
   </div>


   <div>
     <div class="div_label">Цена</div>
     <input type="number" class="inputt" v-model.trim="sena" @keyup="f_keyup('sena')" placeholder="">
  </div> 
  
  <div>
    <br/>
     <div class="div_label">Год выпуска</div>
     <input type="number" class="inputt" v-model.trim="god" @keyup="f_keyup('god')" placeholder="">
  </div>


  <div>
     <br/>
     <div class="div_label">Пробег</div>
     <input type="number" class="inputt" v-model.trim="probeg" @keyup="f_keyup('probeg')" placeholder="">
  </div> 


   <div v-if="display_body">
    <br/>
    <div class="div_label">Описание</div>
     <resizable-textarea ref="resize">
       <textarea ref="textarea" rows="2" class="textareat" v-model.trim="body"   @keyup="f_keyup('body')" placeholder=""></textarea>
     </resizable-textarea>
   </div>
   
   <div>
     <div class="div_label">Цветь</div>
      <router-link :to="{name:'color'}" >
       <div  class="div_border">{{post.color_text}}</div>   
      </router-link>  
   </div>

   <div>
     <div class="div_label">Топлива</div>
      <router-link :to="{name:'topliva'}" >
       <div  class="div_border">{{post.topliva_text}}</div>   
      </router-link>  
   </div>

   <div>
     <div class="div_label">Кузов</div>
      <router-link :to="{name:'kuzov'}" >
       <div  class="div_border">{{post.kuzov_text}}</div>   
      </router-link>  
   </div>

   <div>
     <div class="div_label">Каробка предач</div>
      <router-link :to="{name:'karobka'}" >
       <div  class="div_border">{{post.karobka_text}}</div>   
      </router-link>  
   </div>
  
   <div>
     <div class="div_label">Привод</div>
      <router-link :to="{name:'privod'}" >
       <div  class="div_border">{{post.privod_text}}</div>   
      </router-link>  
   </div>
   
   <div>
     <div class="div_label">Обём двигатель</div>
      <router-link :to="{name:'obyomdvigatel'}" >
       <div  class="div_border">{{post.obdvigatel}}</div>   
      </router-link>  
   </div>

   <div class="checkbox_moderator">
    <div class="checkbox">
      <input class="custom-checkbox" 
        @change="f_great_price()" v-model="great_price"  
        type="checkbox" id="great_price" 
        name="great_price" value="indigo">
      <label for="great_price">Отличная цена</label>
    </div>
   </div>

   <div class="checkbox_moderator">
    <div class="checkbox">
      <input class="custom-checkbox" 
        @change="f_car_bazar()" v-model="car_bazar"  
        type="checkbox" id="car_bazar" 
        name="car_bazar" value="indigo">
      <label for="car_bazar">На базаре</label>
    </div>
   </div>

   <Diska v-if="display_diska"/>
   <div>
     <div class="div_label">Номер телефон</div>
       <div  class="div_border">{{post.tel}}</div>   
   </div>
   
   <div>
     <div class="div_label">Имя</div>
     <input type="text" class="inputt" v-model.trim="name" @keyup="f_keyup('name')" placeholder="">
  </div> 
  <br/>
  
  <div class="gosnomer" v-if="post.gosnomer">Скрыть госномер автомобиля</div>
  <div class="content_upload_img">
    <div class="div_upload_image">
        <div class = "img_pp">
           <img :src =  post.img_p width="100%">
        </div>
 <div  v-for="img in getimages" :key="img.id">
      <div>
        <img :src = img.image   class="img_load"  >
          <button class="btnd" @click="delete_img(img.image)">Удалить</button>
          <button class="btng" @click="glavnay_img(img.image)">Главная</button>
           <router-link :to="{name:'editfoto', params:{image:img.image}}" >
            <button class="btns" >Изменить</button>
           </router-link> 
      </div>
 </div>

   </div>
   
  </div>
   <br/>
   <br/>
   <h3>Похожие объявления автора</h3>
   <div>
        <Karta  :obvs="postAvtor" /> 
    </div>
   <br/>
   <br/>
   <div v-if="content_textnotrue">
   <resizable-textarea ref="resize">
      <textarea ref="textarea" rows="1" class="textareat" v-model.trim="textnotrue"  placeholder="Причина забилакировано"></textarea>
    </resizable-textarea>
   </div>
   <br/> 
   <hr/>
   <br/> 
   <div align="center">
   <button class="btnp" @click="Notrue">ЗАБЛОКИРОВАТЬ</button>
   <button class="btns" @click="Opublikovat">ОПУБЛИКОВАТЬ</button>
    <br/><br/><span @click="popitka=!popitka">del</span> <br/><br/>
    <div v-if="popitka>0"><button class="btnd" @click="deleteobv">Удалить({{popitka}})</button></div>
   </div>
   <br/><br/><br/>
   <modalupload v-if="startupload" >
   <div slot="body">
     <table><tr><td><Spinner /></td><td ><span class="font_22">Идёт загрузка...</span></td></tr></table>
      
   </div>
   </modalupload>
  </div>
  </div> 
</template>

<script>
import ResizableTextarea from "../blocks/ResizableTextarea";
import Spinner from '../blocks/spinner';
import Menu from '../blocks/menu';
import Karta from '../blocks/karta';
import modalupload from '../blocks/modalupload';
import Diska from '../components/diska';
import axios from "axios";
export default {
 
  components:{
    Spinner,  modalupload, ResizableTextarea, Diska, Menu, Karta
  },
  data() {
   
     return {
      image: null,
      popitka:0,
      idicator_upload_image:"",
      img_btn_upload: true,
      blockformtextarea:false,
      title:"",
      body:"",
      probeg:"",
      god:"",
      sena:"",
      name:"",
      display_body:false,
      display_diska:false,
      propsmodalalert:false,
      status_success:true,
      startupload:false,
      content_textnotrue:false,
      textnotrue:"",
      image_p:"",
      imag_p_del:"",
      imgdel:"",
      i:0,
      foto:[

      ],
      status:"",
      podatImage:"",
      ochirad:false,
      popitkadelimg:1,
      car_bazar:false,
      great_price:false,
    };
  },
  methods: {

  f_keyup(value){
    if(value=='title'){
      this.$store.commit("addtitle",this.title); 
    }
    if(value=='sena'){
     this.$store.commit("addsena",this.sena); 
    }
    if(value=='probeg'){
     this.$store.commit("addprobeg",this.probeg); 
    }
    if(value=='body'){
     this.$store.commit("addbody",this.body); 
    }
     if(value=='god'){
     this.$store.commit("addgod",this.god); 
    }
    if(value=='name'){
     this.$store.commit("addname",this.name); 
    }
  },
    
   f_car_bazar(){
    if(this.car_bazar){
      this.$store.commit("addcar_bazar",1); 
    }else{
      this.$store.commit("addcar_bazar",0);  
    }
   },
   f_great_price(){
    if(this.great_price){
      this.$store.commit("addgreat_price",1); 
    }else{
      this.$store.commit("addgreat_price",0); 
    }
   },
   check(){
     this.status_success=true;
     if(this.title<2){
       this.status_success=false;
     }
     if(this.name<2){
       this.status_success=false;
       this.text_success_name="Введите свои имя";
     }
     return this.status_success;
   },
   Opublikovat() {
     if(this.check()){
       if(!this.textnotrue.length){
         this.status=1;
         this.send_post();
       }
       
       else alert("Вы должен заблокировать эту объявлению")
     }
   },
   Notrue() {
     if(this.check()){
       if(!this.content_textnotrue){
         this.content_textnotrue=true;
       }
       else{
         if(!this.textnotrue.length){
           alert("Напишите причина заблокирование объявления")
         }
         else {
          this.status=5;
          this.send_post();
         } 
       }
       
     }
   },
  deleteobv(){
   this.popitka=this.popitka+1;
  if(this.popitka==4){
    this.startupload=true;
   this.delobv();
  }
  },

  delobv(){
     this.startupload=true;
      try{
         axios({
           method: 'post',
            url: 'https://avtobaza.tj/api/delobv',
            data: {
            id:this.post.id,
            tokenadmin:localStorage.getItem("tokenadmin")
           }
          })
        .then(res=>{
            if(res.data.status){
              this.$router.go();
            }
            else{
             this.startupload=false;
            }    
        })    
     } 
     catch (e){
         alert ("Error");
     }
  },
  delete_img(img){
    this.popitkadelimg=this.popitkadelimg+1;
    if(this.popitkadelimg==3){
       this.startupload=true;
       axios({
           method: 'post',
            url: 'https://avtobaza.tj/api/delimg',
            data: {
            image_del:img, pass:localStorage.getItem("tokenadmin")
           }
          })
        .then(res=>{
            if(res.data.status){
               this.startupload=false;
               this.foto = this.getimages.filter(p => p.image !== img);
               this.$store.commit("addimages", this.foto);
            }
            else{ }
            this.popitkadelimg=1;
        }).catch(error => {
         alert("error")
      })  }  
   },


glavnay_img(img){
      this.startupload=true;
 try{
         axios({
           method: 'post',
            url: 'https://imgagros.ru/api/crop',
            data: {
            image_p:img,
           }
          })
        .then(res=>{
            if(res.data.status){
              this.imag_p_del=this.post.img_p;
              this.$store.commit("addimg_p", res.data.answer);
               axios({
                  method: 'post',
                  url: 'https://avtobaza.tj/api/delimg',
                  data: {
                  image_del:this.imag_p_del, pass:localStorage.getItem("tokenadmin")
                }
                })
              .then(res=>{
                  if(res.data.status){
                    this.startupload=false;
                  }
                }).catch(error => {
                alert("error")
                })
            }
            else{ alert("error");}
        }).catch(error => {
        if (!error.response) {
            alert("error");
        }
      })    
     } 
     catch (e){
         alert ("Error");
     } 
   },





send_post(){
  var list=[];
  list = this.getimages;
   for(var i=0;  i<list.length; i++){
      if(this.podatImage!="") this.podatImage = this.podatImage+"|"+list[i].image;
      else this.podatImage = this.podatImage+list[i].image;
   }


   this.startupload=true;
   if(this.sena=="") this.sena=0.0001;
      try{
         axios({
           method: 'post',
            url: 'https://avtobaza.tj/api/activno',
            data: {
            id:this.post.id,
            status:this.status,
            title: this.post.title,
            name: this.post.name,
            gorod: this.post.gorod,
            gorod_text: this.post.gorod_text,
            tip: this.post.tip,
            tip_text: this.post.tip_text,
            kateg: this.post.kateg,
            kateg_text: this.post.kateg_text,
            podkateg: this.post.podkateg,
            podkateg_text: this.post.podkateg_text,
            marka: this.post.marka,
            marka_text: this.post.marka_text,
            model: this.post.model,
            model_text: this.post.model_text,
            god: this.post.god,
            kuzov: this.post.kuzov,
            kuzov_text: this.post.kuzov_text,
            color: this.post.color,
            color_text: this.post.color_text,
            topliva: this.post.topliva,
            topliva_text: this.post.topliva_text,
            karobka: this.post.karobka,
            karobka_text: this.post.karobka_text,
            privod: this.post.privod,
            privod_text: this.post.privod_text,
            kompleckt: this.post.kompleckt,
            kompleckt_text: this.post.kompleckt_text,
            obdvigatel: this.post.obdvigatel,
            probeg: this.post.probeg,
            opsiya: this.post.opsiya,
            id_opsiya:this.post.id_opsiya,
            tipdiski: this.post.tipdiski,
            tipshina: this.post.tipshina,
            profil: this.post.profil,
            diametr: this.post.diametr,
            shirina: this.post.shirina,
            pcd: this.post.pcd,
            sostoyanie: this.post.sostoyanie,
            body: this.post.body,
            sena: this.post.sena,
            torg: this.post.torg,
            chat: this.post.chat,
            great_price:this.great_price,
            car_bazar:this.car_bazar,
            imgp: this.post.img_p,
            images:this.podatImage,
            textnotrue:this.textnotrue,
            tokenadmin:localStorage.getItem("tokenadmin")
           }
          })
        .then(res=>{
          if(!res.data.message){
            if(res.data.status){
               this.$router.go();
            }
            else{
             this.startupload=false;
           
            }
          }
          else{
              localStorage.removeItem("tokenadnim");
              this.$router.go();
          } 
              
        }).catch(error => {
        if (!error.response) {
            this.startupload=false;
            this.propsmodalalert=true;
        }
      })    
     } 
     catch (e){
         alert ("Error");
     }
  },

   getobvproveka(){
     if(localStorage.getItem("tokenadmin")){
       this.startupload=true;
      try{
         axios({
           method: 'post',
            url: 'https://avtobaza.tj/api/getobv',
            data: {
            tokenadmin:localStorage.getItem("tokenadmin"),
           }
          })
        .then(res=>{
            if(res.data.status){
              if(res.data.obv=="no"){
               alert("Пока нет обявления для проверка");
               this.startupload=false;      
              }
              else {
                this.startupload=false;
                this.$store.commit("addpost", res.data.obv);
                this.$store.commit("addpostAvtor", res.data.obvsavtor);
                var list=[];
                var j=1;
                if( res.data.obv.images){
                  res.data.obv.images.split('|').map(function(value) {

                   const newpost = {
                         id: j,
                         image: value
                        };
                        j++
                       list.push(newpost);
                    }); 
                }
                 
              
                this.$store.commit("addimages", list);

                this. render();
                
              } 
            }
            else{
            // this.spin=false;
            }
        }).catch(error => {
        
      })    
     } 
     catch (e){
         alert ("Error");
     } 
     }
   },
   render(){
  
     this.title=this.post.title;
     this.sena=this.post.sena;
     this.probeg=this.post.probeg;
     this.body=this.post.body;
     this.display_body=true;
     this.god=this.post.god;
     this.name=this.post.name;
     this.display_diska=true;
     if(this.post.car_bazar==1) this.car_bazar=true;
     if(this.post.great_price==1) this.great_price=true;
   }
  },
  mounted(){
   
   if(this.post.kateg){
     this.render();
   }
   else {
     this.getobvproveka(); 
   } 

  },

  computed: {
    post() {
      return this.$store.getters.getpost;
    },
    postAvtor() {
      return this.$store.getters.getpostAvtor;
    },
    getimages() {
          return this.$store.getters.getimages;
        },
  },
  created(){
   if(!localStorage.getItem("tokenadmin")){
       this.$router.push({name:"Home"});
    }     
},
};
</script>

<style>
  
#fileInput {
display: none;
}

.div_label {
    height: 16px;
    font-size: 15px;
    color:#555;
    margin-left:3px;
    margin-bottom: 2px;
}
.content_chat{
  margin-left:-15px;
  margin-top:10px;
}
.margin5{
  margin: 5px;
}
.img_pp{
  width:150px;
}
.div_border {
   width:96.5%;
  border: 1px solid #444;
  padding:10px 0px 10px 5px;
  font-size: 18px;
  border-radius: 5px;
  margin-bottom:10px;
}
.div_border svg {
  color:#444;
  margin: 3px 3px 0px 3px;
}
.div_border:hover {
cursor:pointer;
}
.inputt {
  width:95.5%;
  border: 1px solid #444;
  padding:10px 0px 10px 10px;
  font-size: 18px;
  border-radius: 5px;
  margin-bottom:10px;
}
.textareat {
width:95.5%;
  border: 1px solid #444;
  padding:10px 0px 10px 10px;
  font-size: 18px;
  border-radius: 5px;
  margin-bottom:10px;
  resize: none;
}
.success {
  height: 15px;
  font-size: 15px;
  color:#c20404;
  margin-left:3px;
}
.div_upload_image div {

border-radius: 10px;
margin: 5px 5px;

}
.div_upload_image{
   max-width: 600px;
  min-width: 250px;
}
.content_torg_obmen{
display: inline-flex;
margin-left:-11px;
}
.content_sena table{
  width:100%;
}
.text_somoni {
  font-size:20px;
  color:#777777;
}
.td_sena{
  width:100%;
}
.img_load {
width:100%;
border-radius: 5px;
}
.content_moderator{
  margin: 0 auto;
  max-width: 600px;
}
a{
  text-decoration: none;
  color:#222;
}
.gosnomer{
  font-size: 18px;
  color: #c20404;
}
.checkbox_moderator{
  margin-left: -20px;
  margin-top: 7px;
  margin-bottom: 7px;
  font-size: 18px;
}
</style>
