<template>
  <div>
  <Header :title="title"/>

    <div class="content_podat">
      <div v-for="c in arr" :key="c.id" class="block_radio">
        <input
          class="custom-radio"
          name="color"
          v-model="color"
          type="radio"
          :id="c.id"
          :value="c.id"
          @change="select"
        />
        <label :for="c.id">
            <table>
                <tr>
                    <td>
                       <div class="iconkacolor" :id="'c'+c.id"></div> 
                    </td>
                    <td><span>{{c.text}}</span></td>
                </tr>
            </table>
         </label>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/headerpodat'
export default {
   components:{
   Header
   },
  data() {
    return {
      title:"Выберите цвет",
      color: "",
      txtcolor: "",
      set:this.$route.params.set,
       arr: [
        { id: "ffffff", text: "Белый"},
        { id: "f3e3c6", text: "Молочный цвет"},
        { id: "3399cc", text: "Дельфин"}, 
        { id: "505050", text: "Мокрый асфальт"}, 
        { id: "000000", text: "Черный"}, 
        { id: "e1e1e1", text: "Серебристый"}, 
        { id: "a48660", text: "Сахара"}, 
        { id: "4a4540", text: "Перламутрово-коричневый"},
        { id: "c7e232", text: "Жёлто-зеленый"},
        { id: "ab3154", text: "Вишня"},
        { id: "4343ff", text: "Синий"},
        { id: "ff0000", text: "Красный"},
        { id: "a9a9a9", text: "Серый"},
        { id: "964b00", text: "Коричневый"},
        { id: "cd7f32", text: "Бронза"},
        { id: "3c4458", text: "Хамелеон"},
        { id: "30d5c8", text: "Бирюзовый"},
        { id: "f5f5dc", text: "Бежевый"},
        { id: "872424", text: "Бордовый"},
        { id: "00bfff", text: "Голубой"},
        { id: "ffff00", text: "Жёлтый"},
        { id: "00ff00", text: "Зеленый"},
        { id: "c8a2c8", text: "Сиреневый"},
        { id: "a99838", text: "Золотистый"},
        { id: "cd5700", text: "Оранжевый"},
        { id: "ffc0cb", text: "Розовый"},
        { id: "4f2088", text: "Фиолетовый"},
        
      ],
    };
  },
  methods: {
    select() {
        const values = Object.values(this.arr);
        for (let i = 0; i < values.length; i += 1) {
          if (values[i].id === this.color) this.txtcolor = values[i].text;
        }
       
          this.$store.commit("addcolor",this.color);
          this.$store.commit("addcolor_text",this.txtcolor);
          this.$router.go(-1); 

    },
  },
  computed: {
    post() {
      return this.$store.getters.getpost;
    },
   },
  mounted (){
    if(this.post.color){
      this.color=this.post.color;
    }
  }
};
</script>
<style scoped>
.iconkacolor {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    margin-right: 5px;
}
#cffffff{
background-color: #ffffff;
border:1px solid #ccc;
height: 16px;
width: 16px;
}
#cf3e3c6{
background-color: #f3e3c6;
}
#c3399cc{
background-color: #3399cc;
}
#c505050{
background-color: #505050;
}
#c000000{
background-color: #000000;
}
#ce1e1e1{
background-color: #e1e1e1;
}
#ca48660{
background-color: #a48660;
}
#c4a4540{
background-color: #4a4540;
}
#cc7e232{
background-color: #c7e232;
}
#cab3154{
background-color: #ab3154;
}
#c4343ff{
background-color:#4343ff;
}
#cff0000{
background-color:#ff0000;
}
#ca9a9a9{
background-color:#a9a9a9;
}
#c964b00{
background-color: #964b00;
}
#ccd7f32{
background-color: #cd7f32;
}
#c3c4458{
background-color: #3c4458;
}
#c30d5c8{
background-color: #30d5c8;
}
#cf5f5dc{
background-color: #f5f5dc;
}
#c872424{
background-color: #872424;
}
#c00bfff{
background-color: #00bfff;
}
#cffff00{
background-color: #ffff00;
}
#c00ff00{
background-color: #00ff00;
}
#cc8a2c8{
background-color: #c8a2c8;
}
#ca99838{
background-color: #a99838;
}
#ccd5700{
background-color: #cd5700;
}
#cffc0cb{
background-color: #ffc0cb;
}
#c4f2088{
background-color: #4f2088;
}
.block_radio {
  margin-bottom: 1.4em;
}
.divgorod {
  width: 100%;
  color: #777777;
}
.contentgorod {
  font-size: 20px;
  border-bottom: 1px solid #000000;
  padding: 5px 5px 5px 0px;
}
label {
  font-size: 18px;
  width: 100%;
}
.content_podat {
  padding: 10px 5px 65px 10px;
}
</style>