<template>
    <div class="contend_chat">
     <div class="content_head" align="center">
       <br/>
        <div ><div class="title_overflow margin_5"><span>{{name}} {{ teluser2 }}</span></div></div>
      </div>
     <br/>
       <div class="content_kart">  
          <div  v-for="message in messages" :key="message.id">
               <div class="user"  v-if="message.sender">
                 <div v-if="message.text_message.length > 50 && message.text_message.startsWith('https://imgagros.ru')">
                    <img :src = "message.text_message" />
                    <br/><label>{{message.dt}}</label>
                   </div>
                   <div v-else>{{message.text_message}}
                    <br/><label>{{message.dt}}</label>
                   </div>
                </div> 
                <div class="nouser" v-else>
                  <div v-if="message.text_message.length > 50 && message.text_message.startsWith('https://imgagros.ru')">
                    <img :src = "message.text_message" />
                    <br/><label>{{message.dt}}</label>
                   </div>
                   <div v-else>{{message.text_message}}
                    <br/><label>{{message.dt}}</label>
                   </div>
                </div>  
          </div> 
        </div>
        <br/><br/><br/>
        <modalupload v-if="loading" >
          <div slot="body">
            <table><tr><td><Spinner /></td><td ><span class="font_22">Загрузка...</span></td></tr></table>
         </div>
        </modalupload>

    <div class="content_sendmessage" align="center"> 
        <div class="divsend">
       <table>
           <tr>
              <td>
                <image-uploader
                :maxWidth="900"
                :quality="0.8"
                :preview="true"
                accept="image/*"
                :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                capture="environment"
                :debug="1"
                doNotResize="gif"
                :autoRotate= rotate
                outputFormat="verbose"
                @input="setImage"
                @onUpload="startImageResize"
                @onComplete="endImageResize"
                >
                <label  for="fileInput" slot="upload-label">
                <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12 17.5q1.875 0 3.188-1.312T16.5 13t-1.312-3.187T12 8.5T8.813 9.813T7.5 13t1.313 3.188T12 17.5m0-2q-1.05 0-1.775-.725T9.5 13t.725-1.775T12 10.5t1.775.725T14.5 13t-.725 1.775T12 15.5M4 21q-.825 0-1.412-.587T2 19V7q0-.825.588-1.412T4 5h3.15L9 3h6l1.85 2H20q.825 0 1.413.588T22 7v12q0 .825-.587 1.413T20 21zm0-2h16V7h-4.05l-1.825-2h-4.25L8.05 7H4zm8-6"/></svg>
                </div>
              </label>
              </image-uploader>
              </td>
         
               <td width="100%">
                 <div v-if="blockformtextarea">
                 <resizable-textarea ref="resize">
                  <textarea ref="textarea" rows="1" class="textareatt" v-model.trim="text_send_message" placeholder="Ваше сообщение"></textarea>
                  </resizable-textarea>
                 </div>
               </td>
               <td><div @click="sendmessage()"><img src="../assets/image/ic_send_128_28719.png" ></div></td>
           </tr>
      </table> 
        </div>
    </div>
    <noconnection :propsmodalalert="propsmodalalert" @emitnoconnection="emitnoconnection" />
    </div>
</template>
<script>
import ResizableTextarea from '../blocks/ResizableTextarea'
import axios from 'axios';
import noconnection from '../blocks/noconnection'
import Spinner from '../blocks/spinner';
import modalupload from '../blocks/modalupload';
export default{
components:{
    Spinner,   noconnection,  ResizableTextarea, modalupload

  },
data() {
    return {
        title:"dfdfgf",
        loading:false,
        propsmodalalert:false,
        text_send_message:"",
        send_text:"",
        teluser2:"",
        title_obv:"",
        name:"",
        messages:[],
        p:true,
        timer_interval:"",
        pauza:100,
        timer:"",
        pauza1:10,
        timer1:"",
        blockformtextarea:true,
        hasImage: false,
        image: null,
        rotate:true,
      
    }
  },
created(){
    if(!localStorage.getItem("tokenadmin")){
       this.$router.push({name:"Home"});
    } 
},
methods:{
  setImage: function(output) {
      this.hasImage = true;
      this.image =output.dataUrl;
      this.text_success_img="";
     
    },

 
    startImageResize: function (){
      this.loading= true;
    }, 
    endImageResize: function (){
     this.uploadimg();
   },
 emitnoconnection(){
   this.propsmodalalert=false;
   this.getmessages();
 },


 uploadimg(){
    axios({
          method: "post",
         url: "https://imgagros.ru/api/uploadimagemessage",
            data: {
              image: this.image,
            },
        })
          .then((res) => {
            if (res.data.status) {
              this.loading=false;
              this.text_send_message=res.data.answer;
              this.sendmessage();
            } else {
             this.loading=false;
            }
          })
          .catch((error) => {
            if (!error.response) {
              alert("Error111");
            }
          });
   },


 getmessages(){
      if(this.p)  this.loading=true,
         axios({
           method: 'post',
            url: 'https://avtobaza.tj/api/getmessagesadmin',
            data: {
            teluser2:this.teluser2,
            tokenadmin: localStorage.getItem("tokenadmin"),
           }
          })
        .then(res=>{
          if(!res.data.msg){
            if(res.data.status){
               if(res.data.messages){
                  this.messages=res.data.messages;
                }
               this.name=res.data.name.name;
               this.loading=false;
               this.p=false;
               clearTimeout(this.timer);
               this.timer = setTimeout ( () => {
               window.scrollTo(0, document.body.scrollHeight+100 || document.documentElement.scrollHeight+100);
             }, this.pauza);
            }
            else{
              this.loading=false;
              this.p=false;
            }
          }
           else{
             localStorage.removeItem("tokenadmin");
             this.$router.go(-1);
           } 
        }).catch(error => {
        if (!error.response) {
            this.propsmodalalert=true;
        }
      })
 },
 sendmessage(){
       if(this.text_send_message.length > 0){
          this.send_text=this.text_send_message;
          this.blockformtextarea=false;
          this.text_send_message="";
           clearTimeout(this.timer);
             this.timer1 = setTimeout ( () => {
                this.blockformtextarea=true;
             }, this.pauza1);
          const newPost ={
                   id:Date.now(),
                   dt: new Date().toLocaleTimeString(),
                   text_message:this.send_text,
                   sender:true
            };
             clearTimeout(this.timer);
             this.timer = setTimeout ( () => {
               window.scrollTo(0, document.body.scrollHeight+100 || document.documentElement.scrollHeight+100);
             }, this.pauza);
           // window.scrollTo(0,0);
          
            if(this.messages)  this.messages.push(newPost);
         else  this.messages=newPost;
         axios({
           method: 'post',
            url: 'https://avtobaza.tj/api/sendmessageadmin',
            data: {
            tokenadmin: localStorage.getItem("tokenadmin"),
            teluser2:this.teluser2,
            text:this.send_text,
           }
          })
        .then(res=>{
          if(!res.data.msg){
            if(res.data.status){
               console.log("send");

            }
           
          }
           else{
            // localStorage.removeItem("token");
             this.$router.go(-1);
           } 
        }).catch(error => {
        if (!error.response) {
            this.propsmodalalert=true;
        }
      })
    }
 }    
},
mounted(){
   if(!localStorage.getItem("tel2")){
       this.$router.go(-1); 
    }
    else{
        this.teluser2=localStorage.getItem("tel2");
        localStorage.removeItem("tel2");
    }
  this.getmessages();
}
}
</script>
<style scoped>
#fileInput {
display:none;
}
.contend_chat{
background-color: #f5f5f5;
margin:-10px;
}
.content_sendmessage{
min-height:60px;
left: 0px;
bottom:0px;
position: fixed;
min-width:100%;
max-width:100%;
background-color:#ffffff;   
}
.textareatt{
width:100%;
border: 1px solid #fff;
padding:7px 0px 7px 10px;
font-size: 18px;
resize: none;
margin-bottom: -15px;
}
.content_sendmessage img{
width:35px;
padding:5px;
}
.content_sendmessage svg{
 color:#666;
 padding:5px;
}
.content_sendmessage svg:hover{
 color:#19641c;
}
.content_sendmessage .divsend{
margin-top:3px;
max-width:900px;
}
.content_head {
height:54px;
left: 0px;
top:0px;
position: fixed;
min-width:100%;
max-width:100%;
background-color:#ffffff;
box-shadow: 0 0 4px 0 rgba(0,0,0,1);
}
.title-h {
font-size: 20px;
text-decoration: none;
}
.content_head img {
width:28px;
border-radius:5px;
margin-top:3px;
padding:8px 15px 8px 8px;
}
.content_head {
padding-top:0px;
}
.content_head img:hover {
cursor:pointer;
}
.title_overflow{
word-wrap: break-word;
font-size:18px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
}
.title_obv{
word-wrap: break-word;
text-decoration:none;
color:#0066ff;
font-size:18px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
}
.user{
text-align:right;
}
.user label{
  font-size:14px;
  color: #333;
  margin-right: 7px ;
}
.user  div{
font-size:18px;
display: inline-block;
margin:7px;
max-width: 70%;
background-color:#99ccff;
padding:10px 5px 10px 5px;
border-radius:10px;
word-wrap:break-word;
word-break: break-word;
   -webkit-hyphens: auto;
   -moz-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto;	
}
.user img{
  width: 100%;
}
.nouser{
text-align:left;
}
.nouser label{
  font-size:12px;
  color: #333;
}
.nouser  div{
font-size:18px;
max-width: 70%;
display: inline-block;
margin:7px;
background-color:#ffffff;
padding:10px 5px 10px 5px;
border-radius:10px;
word-wrap:break-word;
word-break: break-word;
   -webkit-hyphens: auto;
   -moz-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto;	
}
.nouser img{
  width: 100%;
}
</style>
