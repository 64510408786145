<template >
<div>
<div  v-for="obv in obvs" :key="obv.id">
  <br/>
  <hr/>
  <div class="kart">
    <a :href="`https://avtobaza.tj/obv/${obv.id}`" target="_blank">
  <div>
  <table>
      <tr>
          <td>
              <div class="div_kart_img">
                  <img :src="obv.img_p" class="kart_img" >
              </div>
          </td>
          <td width="100%">
            <div class="margin_8-left">
                <label class="title_cbn">
                <span>{{obv.title}}</span>
                </label>
                <label class="sena"> 
                <div>{{obv.sena}} c.</div> 
                </label>
                <label class="sena"> 
                <div><b>{{obv.status}}</b></div> 
                </label>
                <label class="opisanie">{{obv.body}}</label>
                <label class="adress">{{obv.gorod_text}}</label><br/>
                <label class="data_time">{{obv.dt}}</label>
                
            </div>
          </td>
      </tr>
  </table>
  </div>
</a>
  </div>
</div>
</div>
</template>
<script>
export default {
  components:{
   
  },
     props: {
      obvs: Array,
     }, 
}
</script>
<style>

</style>